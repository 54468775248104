<template>
  <b-container>
    <b-card>
      <b-row>
        <b-col md="5">
          <b-form-group
            label="Mês"
            label-for="month"
          >
            <b-form-select
              v-model="date.month"
              :options="monthOptions"
            />
          </b-form-group>
        </b-col>

        <b-col md="5">
          <b-form-group
            label="Ano"
            label-for="year"
          >
            <b-form-select
              v-model="date.year"
              :options="yearOptions"
            />
          </b-form-group>
        </b-col>

        <b-col
          class="d-flex align-items-center"
          md="2"
        >
          <b-button
            variant="primary"
            class="d-block my-auto w-100"
            @click="getData"
          >
            Consultar
          </b-button>
        </b-col>
      </b-row>

    </b-card>

    <b-card>
      <b-row>
        <b-col
          v-for="item in fields"
          :key="item"
          class="mb-1"
          md="12"
        >
          <b-row class="justify-content-center">
            <b-col
              class="d-flex align-items-center"
              md="3"
            >
              {{ translator[item.key] }}
            </b-col>

            <b-col md="3">
              <b-form-input
                v-if="item.mask === 'money'"
                v-model="data[item.key]"
                v-money
                :placeholder="translator[item.key]"
              />
              <b-form-input
                v-else
                v-model="data[item.key]"
                v-mask="['#,##', '##,##', '###,##']"
                :placeholder="translator[item.key]"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row
        class="justify-content-center my-1"
        style="gap: 8px"
      >

        <b-button
          variant="primary"
          @click="create"
        >
          Salvar Taxa
        </b-button>

        <b-button
          variant="primary"
          @click="generateReport"
        >
          Gerar Relatório
        </b-button>

      </b-row>

    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BFormGroup, BFormSelect, BRow, BCol, BCard, BFormInput, BButton,
} from 'bootstrap-vue';

import ClientRentability from '@/service/client-rentability';

export default {
    components: {
        BFormInput,
        BContainer,
        BFormGroup,
        BFormSelect,
        BRow,
        BCol,
        BButton,
        BCard,
    },

    data() {
        return {
            date: {
                month: String(new Date().getUTCMonth()).padStart(2, '0'),
                year: new Date().getUTCFullYear(),
            },
            data: {
                tax_rentability_fidc_soulpay: '0',
            },
            fields: [
                { key: 'pis_tax', mask: 'money' },
                { key: 'cofins_tax', mask: 'money' },
                { key: 'iss_tax', mask: 'money' },
                { key: 'veripag_tax', mask: 'money' },
                { key: 'paygo_tax', mask: 'money' },
                { key: 'gsurf_tax', mask: 'money' },
                { key: 'maxipago_tax', mask: 'money' },
                { key: 'depreciation_pos', mask: 'money' },
                { key: 'bankslip_tax', mask: 'money' },
                {
                    key: 'tax_rentability_fidc_soulpay',
                    mask: '',
                },
            ],

            translator: {
                pis_tax: 'PIS',
                cofins_tax: 'CONFINS',
                iss_tax: 'ISS',
                veripag_tax: 'VERIPAG',
                paygo_tax: 'PAYGO',
                gsurf_tax: 'GSURF',
                maxipago_tax: 'MAXIPAGO',
                depreciation_pos: 'Depreciação',
                bankslip_tax: 'Boleto',
                tax_rentability_fidc_soulpay: 'Rentabilidade de Taxa Soulpay',
            },
        };
    },

    computed: {
        monthOptions() {
            return [
                { text: 'Selecione o mês', value: null },
                { text: 'Janeiro', value: '01' },
                { text: 'Fevereiro', value: '02' },
                { text: 'Março', value: '03' },
                { text: 'Abril', value: '04' },
                { text: 'Maio', value: '05' },
                { text: 'Junho', value: '06' },
                { text: 'Julho', value: '07' },
                { text: 'Agosto', value: '08' },
                { text: 'Setembro', value: '09' },
                { text: 'Outubro', value: '10' },
                { text: 'Novembro', value: '11' },
                { text: 'Dezembro', value: '12' },
            ];
        },

        yearOptions() {
            return [
                new Date().getUTCFullYear(),
            ];
        },
    },

    async mounted() {
        this.getData();
    },

    methods: {
        removeSymbolsMoney(item) {
            const removeSymbolsMoney = value => (typeof value === 'string'
                ? +(value.replace(/[R$ .]/g, '').replace(',', '.'))
                : value);
            return removeSymbolsMoney(item);
        },

        async create() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente cadastrar essas taxas');

            if (!isConfirmed) {
                return;
            }

            const input = Object.keys(this.translator).reduce((acc, item) => {
                if (this.translator[item] === undefined) {
                    return acc;
                }
                acc[item] = this.removeSymbolsMoney(this.data[item]);
                return acc;
            }, {});

            this.callLoading(true);
            const { status } = await ClientRentability.createTax({
                ...input,
                month: `${this.date.month}/${this.date.year}`,
            });
            this.callLoading(false);

            if (status === 200) {
                this.modalSuccess('Taxas editadas com sucesso');
            } else {
                this.modalError('Erro ao editar taxas');
            }
        },

        async generateReport() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente gerar esse relatório?');
            console.log(isConfirmed);
            if (!isConfirmed) {
                return;
            }

            ClientRentability.generateReport({
                month: `${this.date.month}/${this.date.year}`,
            }).then();

            this.modal('Seu relatório está sendo gerado, quando ele terminar será enviado uma mensagem no seu WhatsApp.');
        },

        async getData() {
            this.callLoading(true);
            const { month, year } = this.date;

            const { data, status } = await ClientRentability.getTax({
                month: `${month}/${year}`,
            });

            if (status === 200) {
                this.data = data;
            }
            this.callLoading(false);
        },
    },
};
</script>
